import React from 'react'

import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

export default function NotFoundPage() {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t('404.title')} />
      <div class="min-h-screen flex items-center">
        <div class="container mx-auto p-4">
          <div class="text-center md:text-left p-4">
            <div class="text-6xl font-medium">
              <Trans i18nKey="404.headline" />
            </div>
            <div class="text-xl md:text-3xl font-medium mb-4">
              <Trans i18nKey="404.subline" />
            </div>
            <div class="text-lg mb-8">
              <Trans i18nKey="404.copy" />
            </div>
            <a href="/" class="border border-white rounded p-4">
              <Trans i18nKey="404.cta" />
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}
